import ActionArea from "../Utilities/ActionArea";
import FeedbackInfo from "./FeedbackInfo";

export default {
  components: {ActionArea, FeedbackInfo},
  template: `
<div class="ld-feedback-card-set"> 
  <div class="ld-feedback-comment">
     <p v-if="feedback.edits.comment.saved"><b>Comment: </b>{{ feedback.edits.comment.saved }}</p>
     <p v-if="feedback.edits.file.saved">A file was uploaded to supplement the comment. </p>
     <p v-if="feedback.edits.textReplacement.saved"><b>Text suggestion: </b>{{ getTextReplacement() }}</p>
     <p v-if="feedback.edits.imageReplacement.saved"><b>New image suggestion. </p>
  </div>
  <FeedbackInfo :feedback="feedback" />
</div>
  `,
  props: {
    feedback: Object,
  },
  data() {
    return {}
  },
  computed: {},
  methods: {
    getTextReplacement() {
      if (!this.feedback.edits || !this.feedback.edits.textReplacement.saved || !this.feedback.domElement.content) return false
      let textReplacement = this.feedback.edits.textReplacement.saved
      if (typeof textReplacement === 'string') {
        textReplacement = textReplacement
          .substring(1, this.feedback.edits.textReplacement.saved.length - 1)
          .split(",")
        return this.feedback.domElement.content
          .map((item) => {
            const edit = textReplacement[item.childIndex]
            if (edit && edit !== 'null') return textReplacement[item.childIndex].substring(1, edit.length - 1)
            return item.text
          })
      } else if (Array.isArray(textReplacement)) {
        return textReplacement.join(" ")
      }
      return ""
    },
  },
  mounted() {
    this.getTextReplacement()
  }
}
