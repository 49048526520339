export default {
  template: `
    <figure class="ld-image">
      <img :src="source" />
    </figure>   
`,
  props: {
    path: {
      type: String,
      default: 'tutorial-image-1'
    },
    filetype: {
      type: String,
      default: 'png'
    },
  },
  data() {
    return {
      source: `${process.env.NODE_BASE_URL}assets/images/${this.path}.${this.filetype}`
      // source: `https://cdn-dev.loopdesk.io/assets/images/${this.path}.${this.filetype}`
    }
  },
  methods: {},
  mounted() {

  }
}