class Billboard {
  constructor() {
    const divContainer = document.createElement('DIV')
    const span = document.createElement('PRE')
    divContainer.style.display = 'none'
    divContainer.style.position = 'fixed'
    divContainer.style.inset = 'auto auto 20px 20px'
    divContainer.style.zIndex = '100000'
    divContainer.style.backgroundImage = 'linear-gradient(81.81deg, #FF0000 -24.38%, #FF985F 158.07%)'
    divContainer.style.color = '#fff'
    divContainer.style.padding = '10px'
    divContainer.style.borderRadius = '5px'
    divContainer.classList.add('ld-billboard')
    divContainer.appendChild(span)
    this.domElement = divContainer
    this.textElement = span
    this.message = ""
    this.init()
  }

  init() {
    document.body.appendChild(this.domElement)
  }

  write(message) {
    this.toggle()
    this.message = message
    this.textElement.innerText = "Loopdesk: " + message
  }

  toggle() {
    this.domElement.style.display = 'block'
    setTimeout(() => {
      this.domElement.style.display = 'none'
    }, 4000);
  }

}


export default new Billboard()