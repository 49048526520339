import Icon from "./Icon";

export default {
  components: {Icon},
  template: `
    <div class="ld-action-area" ref="actionArea">
        <button class="ld-iconed" @click="clickReturnButton">
            <Icon icon="arrow-small" />
            <span>{{ actions[0] }}</span>
        </button>
        <button v-if="actions[1]" class="ld-filled" :class="{'disabled': disabledAction, 'hidden': hiddenAction }" @click="clickActionButton">{{ actions[1] }}</button>
    </div>
`,
  props: {
    actions: {
      type: Array,
      default: ['', '']
    },
    disabledAction: {
      type: Boolean,
      default: false
    },
    hiddenAction: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {}
  },
  methods: {
    clickActionButton() {
      this.$emit('action')
    },
    clickReturnButton() {
      this.$emit('actionReturn')
    },
  },
  mounted() {

  }
}
