import Icon from "../../Utilities/Icon";

export default {
  components: {Icon},
  template: `
<div class="ld-toolbar-block toggle">
    <p class="block-title">Help</p>
    <div v-for="button in buttons" :key="button.id" @click="callFunction(button)" class="ld-setting-wrapper">
         <button class="ld-unfilled ld-iconed">
            <Icon :icon="button.icon"/>
            <span>{{button.title }}</span>
        </button>
    </div>
</div>
  `,
  props: {
    title: {
      type: String,
      default: "Settings"
    }
  },
  data() {
    return {
      buttons: [
        {
          id: 'openTutorial',
          title: "open tutorial",
          icon: "tutorial",
          function: () => this.openTutorial(),
          onToggle: function () {
            this.function()
          },
        },
      ]
    }
  },
  computed: {},
  methods: {
    openTutorial() {
      window.EventBus.trigger('openOverviewInstructions')
    },
    callFunction(button) {
      button.onToggle()
    }
  },
}
