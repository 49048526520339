import {breakpoints} from '../../globals/constants'
import LoadingSpinner from "../Utilities/LoadingSpinner";

export default {
  components: {LoadingSpinner},
  template: `
<footer class="ld-toolbar-footer" :class="{'visible': state.loading}"> 
  <div class="ld-state">
    <LoadingSpinner />
    <p>{{ state.message }}</p>
  </div>
</footer>
  `,
  data() {
    return {
      state: {
        loading: false,
        message: "",
      }
    }
  },
  props: {
  },
  computed: {},
  methods: {
    listenToFeedbackFetchState() {
      window.EventBus.on('startFetchFeedbacks', () => {
        this.state.loading = true
        this.state.message = "loading feedbacks"
      })
      window.EventBus.on('finishedFetchFeedbacks', () => {
        this.state.loading = false
        this.state.message = ""
      })
    }
  },
  mounted() {
    this.listenToFeedbackFetchState()
  }

}
