import IntroMain from './Intro/IntroMain'
import IntroHeader from "./Intro/IntroHeader"

export default {
  components: {IntroHeader, IntroMain},
  template: `
<article class="ld-intro" > 
  <div class="ld-intro-modal">
    <IntroHeader />
    <IntroMain :content="intro[currentIndex]" @continue="updateIndex" :signIn="currentIndex === indexSignInForm"/>
  </div>
</article>
  `,
  data() {
    return {
      project: this.$root.getProject(),
      currentIndex: 0,
      indexSignInForm: 1,
      intro: [
        {
          title: "Launch Loopdesk",
          text: "Welcome to Loopdesk. Are you here to give feedback on the website or do you just want to browse the site?",
          buttons: {
            continue: "Launch Loopdesk",
            back: "Continue Browsing"
          },
          happenings: {
            back: () => this.leaveLoopdesk()
          },
          validationBeforeContinue: false
        },
        {
          title: "Sign in",
          text: "Enter you name and your role as feedbacker.",
          buttons: {
            back: "Go Back",
            continue: "Start feedbacking"
          },
          happenings: {
            continue: () => this.startLoopdesk(),
          },
          validationBeforeContinue: true
        },
      ],
      nextEvents: {
        onGoBack: null,
        onGoForwards: null
      }

    }
  },
  computed: {},
  methods: {
    updateIndex(goForwards = true) {
      if (goForwards) {
        this.currentIndex = Math.min(this.currentIndex + 1, this.intro.length - 1)
        if (typeof this.nextEvents.onGoForwards === 'function') this.nextEvents.onGoForwards()
      } else {
        this.currentIndex = Math.max(this.currentIndex - 1, 0)
        if (typeof this.nextEvents.onGoBack === 'function') this.nextEvents.onGoBack()
      }
      this.nextEvents.onGoBack = this.intro[this.currentIndex].happenings.back || null
      this.nextEvents.onGoForwards = this.intro[this.currentIndex].happenings.continue || null
    },
    leaveLoopdesk() {
      window.EventBus.trigger('leaveLoopdesk')
    },
    startLoopdesk() {
      this.project.saveUser()
      this.$emit('removeIntro')
    }
  },
  mounted() {
    this.nextEvents = {
      onGoBack: this.intro[this.currentIndex].happenings.back || null,
      onGoForwards: this.intro[this.currentIndex].happenings.continue || null,
    }
  }
}
