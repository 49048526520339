import Icon from "../../Utilities/Icon";
import {breakpoints} from "../../../globals/constants";

export default {
  components: {Icon},
  template: `
<div class="ld-toolbar-block toggle ld-block-infos" >
    <p class="block-title">Infos</p>
    <p>Hi <b>{{ name }}</b>. You are currently in the feedback loop named <b>{{ loop }}</b>. Your viewport is currently at <b>{{ windowWidth }} pixels</b> width.</p>
    <p>This project was created with the <b>{{ planName }}</b> plan. <span class="span" v-if="planFeedbacksLimited">You can still submit <b>{{ maxFeedbacks - usedFeedbacks }} feedbacks</b>.</span></p>
</div>
  `,
  props: {},
  data() {
    return {
      windowWidth: window.innerWidth,
      project: this.$root.getProject(),
    }
  },
  computed: {
    device() {
      const breakpoint =
        this.windowWidth > 0 ?
          breakpoints.find((point, index) => {
            const min = index < 1 ? 0 : breakpoints[index - 1][0]
            const max = point[0]
            return this.windowWidth >= min && this.windowWidth < max
          })
          : {}
      return breakpoint[1] || 'Desktop'
    },
    name() {
      return this.$root.getUserName()
    },
    loop() {
      return this.$root.getLoopName()
    },
    usedFeedbacks() {
      return this.project.plan.feedbackCount.current
    },
    maxFeedbacks() {
      return this.project.plan.feedbackCount.max
    },
    planName() {
      return this.project.plan.name
    },
    planFeedbacksLimited() {
      return this.project.plan.feedbacksLimited
    }
  },
  methods: {},
  mounted() {
    window.addEventListener('resize', (e) => {
      this.windowWidth = window.innerWidth
    })
  }
}
