export default {
  template: `
<div class="ld-loading-spinner">
  <div></div>
  <div></div>
</div>
`,
  props: {
  },
  data() {
    return {
    }
  },
  methods: {},
  mounted() {

  }
}
