export default {
  components: {},
  template: `
    <div class="ld-feedback-wrapper" :style="style" >
      <div class="ld-feedback-marker-dot" :class="view" @click="$emit('clicked')"></div>
      <div style="transform: translate(-25px, 35px);" class="ld-slot-wrapper">
         <slot></slot>
      </div>
    </div>  
  `,
  props: {
    position: Object,
    currentWindowWidth: Number,
    feedbackWindowWidth: Number,
  },
  data() {
    return {
      inViewMax1: this.feedbackWindowWidth + 35,
      inViewMin1: this.feedbackWindowWidth - 35,
      inViewMax0: this.feedbackWindowWidth + 100,
      inViewMin0: this.feedbackWindowWidth - 100,
    }
  },
  computed: {
    style() {
      const display = this.currentWindowWidth < this.position.x ? 'none' : 'initial'
      const top = this.position.y + 'px'
      const left = this.position.x + 'px'
      return {top, left, display}
    },
    view() {
      if (this.currentWindowWidth > this.inViewMin1 && this.currentWindowWidth < this.inViewMax1) return 'in-view'
      else if (this.currentWindowWidth > this.inViewMin0 && this.currentWindowWidth < this.inViewMax0) return 'focus'
      return "out-of-reach"
    }
  },
  methods: {},
  mounted() {
  }
}
