const CryptoJS = require("crypto-js");

export default class User {
  constructor() {
    const storedEmail = localStorage.getItem('loopdesk.feedbacker_email') || false
    const storedUsername = localStorage.getItem('loopdesk.feedbacker_username') || false
    const storedRole = localStorage.getItem('loopdesk.feedbacker_role') || false
    this.name = storedUsername ? this.decrypt(storedUsername) || "" : ""
    this.email = storedEmail ? this.decrypt(storedEmail) || "" : ""
    this.role = storedRole ? this.decrypt(storedRole) || "" : ""
    this.saveUser()
  }


  update(name, email, role) {
    this.name = name ? name : this.name
    this.email = email ? email : this.email
    this.role = role ? role : this.role
  }

  saveUser() {
    this.saveInLocalstorage()
  }

  encrypt(string) {
    if (string) return CryptoJS.AES.encrypt(string, 'somesecretkey').toString()
    return ""
  }

  decrypt(string) {
    if (string) return CryptoJS.AES.decrypt(string, 'somesecretkey').toString(CryptoJS.enc.Utf8)
    return false
  }

  saveInLocalstorage() {
    localStorage.setItem('loopdesk.feedbacker_email', this.encrypt(this.email))
    localStorage.setItem('loopdesk.feedbacker_username', this.encrypt(this.name))
    localStorage.setItem('loopdesk.feedbacker_role', this.encrypt(this.role))
  }

  remove() {
    localStorage.removeItem('loopdesk.feedbacker_email')
    localStorage.removeItem('loopdesk.feedbacker_username')
    localStorage.removeItem('loopdesk.feedbacker_role')
  }

  isValid() {
    return this.name && this.email
  }


}