import firebase from "./globals/services/firebase"
import Feedback from "./globals/constructors/Feedback/Feedback";
import FeedbackType from "./globals/constructors/Feedback/FeedbackType";


export default {
  state: {
    feedbacks: [],
    feedbackTypes: [
      new FeedbackType('element'),
      new FeedbackType('dot'),
      new FeedbackType('content'),
    ],
    feedbacksVisible: true,
    project: null,
    activeFeedbackMode: null,
    isFeedbacking: false,
    mobileView: window.innerWidth <= 960,
  },
  getIsFeedbacking() {
    return this.state.isFeedbacking
  },
  setIsFeedbacking() {
    this.state.isFeedbacking = !!this.state.feedbacks.find(it => it.isDraft && it.isOpen)
  },
  setMobileView(mobile = true) {
    this.state.mobileView = mobile
  },
  getMobileView() {
    return this.state.mobileView
  },
  toggleFeedbacksVisibility() {
    this.state.feedbacksVisible = !this.state.feedbacksVisible
  },
  setFeedbacksVisibility(visible = true) {
    this.state.feedbacksVisible = visible
  },
  getFeedbacksVisibility() {
    return this.state.feedbacksVisible
  },
  setFeedbackMode(mode = null) {
    const currentMode = this.state.activeFeedbackMode
    this.state.activeFeedbackMode = currentMode === mode ? null : mode
  },
  getActiveFeedbackMode() {
    return this.state.activeFeedbackMode
  },
  setProject(project) {
    this.state.project = project
  },
  getProject() {
    return this.state.project
  },
  updateFeedbackCount(count) {
    this.state.project.setFeedbackCount(count)
  },
  getHasUser() {
    return this.state.project.hasUser()
  },
  getUserName() {
    if (this.state.project) {
      return this.state.project.getUserName()
    } else {
      return false
    }
  },
  getUser() {
    if (this.state.project) {
      return this.state.project.user
    } else {
      return false
    }
  },
  getLoopName() {
    if (this.state.project) {
      return this.state.project.getLoopName()
    } else return ""
  },
  getFeedbackType(name) {
    if (!name) return false
    return this.state.feedbackTypes.find(type => type.id === name) || false
  },
  getFeedbackTypes() {
    if (this.state.mobileView) return this.state.feedbackTypes.filter(type => type.mobileSupport)
    return this.state.feedbackTypes
  },
  getFeedbacks() {
    return this.state.feedbacks
  },
  getFeedbackById(id = '') {
    return this.state.feedbacks.find(fb => fb.clientID === id) || null
  },
  addFeedback(feedback) {
    this.state.feedbacks.push(feedback)
  },
  deleteFeedback(id) {
    this.state.feedbacks = this.state.feedbacks.filter(item => item.clientID !== id)
  },
  createFeedback(x, y, windowWidth, windowHeight, scrollX, scrollY, element_path) {
    const feedback = new Feedback()
    const user = this.state.project.user
    const feedbackType = this.state.feedbackTypes.find(type => type.id === this.state.activeFeedbackMode)
    if (feedback && user && feedbackType) {
      feedback.initNewFeedback(x, y, windowWidth, windowHeight, scrollX, scrollY, feedbackType, element_path)
      feedback.setFeedbacker(user.name, user.email, user.role, true)
      this.addFeedback(feedback)
    } else {
      console.error("Feedback could not be created")
    }
  },
  async loadFeedbacks() {
    await firebase.fetchFeedbacks()
      .then((data) => {
        this.insertFetchedFeedback(data)
      })
      .catch((error) => {
        console.log(error)
      })
  },
  insertFetchedFeedback(feedbacks) {
    const user = this.state.project.user
    if (feedbacks && feedbacks.length > 0) {
      for (const feedback of feedbacks) {
        const feedbackType = this.state.feedbackTypes.find(type => type.id === feedback.feedback_type)
        const feedbackInstance = new Feedback()
        const isCurrentUser = user ? feedback.feedback_email === user.email : false
        feedbackInstance.initFromExistingFeedback(feedback, feedbackType, isCurrentUser)
        this.state.feedbacks.push(feedbackInstance)
      }
    }
  },
  getFeedbacksByRoute(href) {
    return this.state.feedbacks.filter(feedback => {
      return feedback.pageInfos.url === href.split('?')[0]
    })
  },
  updateFeedbackFeedbacker() {
    for (const feedback of this.state.feedbacks) {
      feedback.updateCurrentUser(this.state.project.user)
    }
  }
}






