
export default {

  addScript: (url) => {
    const scriptEle = document.createElement("script");
    scriptEle.setAttribute("src", url);
    scriptEle.setAttribute("type", "text/javascript");
    return scriptEle
  },

  init: function() {
    const script = this.addScript("https://cdn.jsdelivr.net/npm/vue@2.6.14")
    document.body.appendChild(script);

    return new Promise((resolve, reject) => {
      script.addEventListener("load", () => resolve());
      script.addEventListener("error", (ev) => reject(Error(ev.toString())));
    })
  }
}


