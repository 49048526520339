import SignInForm from "./ModalComponents/SignInForm";
import ActionArea from "../Utilities/ActionArea";

export default {
  components: { ActionArea, SignInForm },
  template: `
<main class="ld-intro-modal-main"> 
    <div class="ld-info-area">
        <span class="ld-info-title">{{ content.title }}</span> 
        <p>{{ content.text }}</p>
    </div>
    
    <SignInForm v-if="signIn" @formValidUpdate="allowContinuation"/>
    
    <ActionArea 
        :actions="[content.buttons.back, content.buttons.continue]"
        :disabledAction="continueDisallowed"
        @action="goForwards" @actionReturn="goForwards(false)"
    />
   
</main>
  `,
  data() {
    return {
      continueDisallowed: this.content.validationBeforeContinue
    }
  },
  props: {
    content: Object,
    signIn: Boolean
  },
  watch: {
    'content.validationBeforeContinue'(value) {
      this.continueDisallowed = value
    }
  },
  computed: {},
  methods: {
    goForwards(forwards = true) {
      this.$emit('continue', forwards)
    },
    allowContinuation(formIsValid = true){
      this.continueDisallowed = !formIsValid
    }
  },
  mounted() {

  }

}
