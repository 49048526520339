import FeedbackContentSet from "./FeedbackContentSet";
import FeedbackContentDraft from "./FeedbackContentDraft";
import Icon from "../Utilities/Icon";
import ActionArea from "../Utilities/ActionArea";
import ButtonDeleteFeedback from "./ButtonDeleteFeedback";

export default {
  components: {FeedbackContentSet, FeedbackContentDraft, Icon, ActionArea, ButtonDeleteFeedback},
  template: `
<div class="ld-feedback-card" :class="{ 'draft': isDraft }">
  <main class="ld-feedback-card-main">
    <FeedbackContentDraft :feedback="feedback" v-show="isFeedbackDraft" @feedbackUploaded="closeFeedback" @feedbackUpdated="exitEditableMode" ref="draftCard"/>
    <FeedbackContentSet v-show="!isFeedbackDraft" :feedback="feedback"/>
    <ActionArea 
        :actions="[cancelOrCloseAction.text, saveOrEditAction.text]"
        :disabledAction="saveOrEditAction.disabled"
        :hiddenAction="saveOrEditAction.hidden"
        @action="saveOrEditAction.action"
        @actionReturn="cancelOrCloseAction.action"
    />
    <ButtonDeleteFeedback v-show="isInEditMode" :feedback="feedback" />
  </main>
</div>
  `,
  props: {
    feedback: Object,
    open: Boolean
  },
  data() {
    return {
      hasDraftInputs: false,
      loadingForUpload: false,
      isInEditMode: false,
      actions: {
        openDraft: {
          action: this.openEditMode,
          text: 'Edit feedback',
          disabled: true,
          hidden: true
        },
        save: {
          action: this.saveFeedback,
          text: 'Save feedback',
          disabled: true,
          hidden: false
        },
        close: {
          action: this.closeFeedback,
          text: 'Close Feedback',
          disabled: false,
          hidden: false
        },
        cancel: {
          action: this.cancelFeedback,
          text: 'Cancel',
          disabled: false,
          hidden: false
        },
      }
    }
  },
  computed: {
    saveOrEditAction() {
      if (!this.isFeedbackDraft) return this.actions.openDraft
      return this.actions.save
    },
    cancelOrCloseAction() {
      // if (this.isFeedbackDraft && !this.hasDraftInputs) return this.actions.cancel
      if (this.isFeedbackDraft) return this.actions.cancel
      return this.actions.close
    },
    isDraft() {
      return this.feedback ? this.feedback.isDraft : true
    },
    isFeedbackDraft() {
      return this.isDraft || this.isInEditMode
    },
  },
  watch: {
    'feedback.isDraft': () => {
      window.EventBus.trigger('feedbackStateChange')
    },
    'feedback.isOpen': () => {
      window.EventBus.trigger('feedbackStateChange')
    },
    'feedback.edits': {
      deep: true,
      handler(edits) {
        this.hasDraftInputs = Object.values(edits)
          .some((edit) => {
            if (typeof edit.draft === 'string') return edit.draft !== ''
            else if (Array.isArray(edit.draft)) {
              return edit.draft.some(contentInputs => contentInputs !== null)
            }
            return false
          })
        this.actions.save.disabled = !this.hasDraftInputs
      }
    },
    'feedback.feedbacker.isCurrentUser': {
      handler() {
        this.actions.openDraft.disabled = !this.feedback.feedbacker.isCurrentUser
        this.actions.openDraft.hidden = !this.feedback.feedbacker.isCurrentUser
      }
    },
  },
  methods: {
    exitEditableMode() {
      this.isInEditMode = false
    },
    openEditMode() {
      this.isInEditMode = true
    },
    closeFeedback() {
      this.isInEditMode = false
      this.$emit('close')
    },
    saveFeedback() {
      if (this.isDraft) this.$refs.draftCard.uploadFeedback()
      else this.$refs.draftCard.updateFeedback()
    },
    cancelFeedback(){
      this.isInEditMode = false
      this.feedback.resetEdits()
      this.$emit('close')
    }
  },
  mounted() {
    this.actions.openDraft.disabled = !this.feedback.feedbacker.isCurrentUser
    this.actions.openDraft.hidden = !this.feedback.feedbacker.isCurrentUser


    document.addEventListener('keydown', (event) => {
      if (!this.open || event.defaultPrevented) return
      if (event.key === "Escape") {
        this.cancelOrCloseAction.action()
      }
      if ((event.ctrlKey && event.key === 's') || (event.ctrlKey && event.key === 'Enter')) {
        this.saveOrEditAction.action()
      }

    });

  }
}
