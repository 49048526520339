export default class EventBus {
  constructor() {
    this.bus = document.createElement('eventbus');
  }

  on(event, callback) {
    this.bus.addEventListener(event, callback);
  }

  remove(event, callback) {
    this.bus.removeEventListener(event, callback);
  }

  trigger(event, detail = {}) {
    this.bus.dispatchEvent(new CustomEvent(event, {detail}));
  }
}