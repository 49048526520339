export default {
  template: `
 <div class="ld-dropdown-wrapper">
     <label for="id">{{label}}</label>
     <select :id="id" :name="id" @change="setInput">
       <option v-for="option in options">{{ option}}</option>
     </select>
 </div>
`,
  props: {
    label: {
      type: String,
      default: ''
    },
    options: {
      type: Array,
      default: []
    }
  },
  data() {
    return {
      id: `ld-dropdown-id-${Date.now()}`
    }
  },
  methods: {
    setInput(e) {
      this.$emit('setInput', e.target.value)
    }
  },
  mounted() {

  }
}