import Icon from "../../Utilities/Icon";

export default {
  components: { Icon },
  template: `
<div class="ld-toolbar-toggle">
    <button class="small ld-iconed" @click="toggleExpanded">
        <span>{{ expand ? 'Hide options' : 'Show options' }}</span>
        <Icon icon="arrow-small" />
    </button>
</div>
  `,
  data() {
    return {
      title: "Infos",
      expand: this.expanded
    }
  },
  props: {
    expanded: Boolean
  },
  watch: {
    expanded(value){
      this.expand = value
    }
  },
  computed: {},
  methods: {
    toggleExpanded() {
      this.expand = !this.expand
      this.$emit('expand', this.expand)
    },
  },
}
