import domElementToObject from "../globals/constructors/Utils/domElementToObject";
import CursorClient from "../globals/constructors/Utils/Cursor";
import TwinObserver from "../globals/constructors/Utils/TwinObserver";
import MobileCursorClient from "../globals/constructors/Utils/MobileCursor";

export default {
  components: {},
  template: `
<article class="ld-selector-layer">
  <div class="ld-selector-gatekeeper" :class="{'is-observing': element.isObserving}" ref="gatekeeper"></div>
  <div id="ld-cursor" :class="[feedbackMode]" v-if="feedbackMode"></div>
</article>
  `,
  props: {},
  data() {
    return {
      cursor: new CursorClient(),
      mobileCursor: null,
      element: {
        origin: null,
        twin: null,
        isObserving: false,
        error: false
      },
      clickCounter: 0,
      acceptMobileClick: false
    }
  },
  computed: {
    feedbackMode() {
      return this.$root.getActiveFeedbackMode()
    },
    isMobile() {
      return this.$root.getMobileView()
    }
  },
  watch: {
    feedbackMode(value) {
      this.clickCounter = 0
      if (value) {
        if (this.isMobile) {
          this.mobileCursor.start(this.feedbackMode)
          this.addTouchClickEventListener()
        } else {
          this.cursor.start()
          this.addClickEventListener()
        }
      } else {
        if (this.isMobile) {
          this.mobileCursor.stop()
          this.addTouchClickEventListener(false)
        } else {
          this.cursor.stop()
          this.addClickEventListener(false)
          this.removeObserver()
        }
      }
    },
  },
  methods: {
    removeObserver() {
      if (this.element.isObserving) {
        this.element.twin.stop()
        this.element = {
          origin: null,
          twin: null,
          isObserving: false,
          error: false
        }
      }
    },
    handleElementChange({detail}) {
      this.removeObserver()
      const element = detail.result || detail.element
      if (element) {
        this.element.origin = element
        this.element.twin = new TwinObserver(this.element.origin, this.$refs.gatekeeper, 'selector-layer-twin')
        this.element.twin.init()
          .then(() => {
            this.element.isObserving = true
            this.alignSlotWrapper()
          })
          .catch(() => this.element.error = true)
      }
    },
    handleDocumentClick(e) {
      const handleValid = this.clickCounter > 0 && (this.feedbackMode === 'dot' || this.element.origin)
      this.clickCounter++
      if (handleValid) {
        this.$emit('newFeedback', {
          path: domElementToObject.encode(this.element.origin),
          pageX: e.pageX,
          pageY: e.pageY
        })
      }
    },
    addClickEventListener(add = true) {
      const method = this.handleDocumentClick
      if (add) {
        window.addEventListener(('click'), method)
      } else {
        window.removeEventListener(('click'), method)
      }
    },
    handleMobileClick({detail}) {
      if (this.acceptMobileClick) {
        console.log("mobile click")
        this.$emit('newFeedback', {
          path: domElementToObject.encode(detail.targetElement),
          pageX: detail.x,
          pageY: detail.y
        })
      }
    },
    addTouchClickEventListener(add = true) {
      this.acceptMobileClick = add
    }
  },
  mounted() {
    this.mobileCursor = new MobileCursorClient()
    window.EventBus.on('hoverValidElement', (e) => {
      this.handleElementChange(e)
    })
    window.EventBus.on('mobileHoverElement', (e) => {
      this.handleElementChange(e)
    })
    window.EventBus.on('mobileClick', (e) => {
      this.handleMobileClick(e)
    })
  }
}
