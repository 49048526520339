import Icon from "./Icon";
import InputFile from "./InputFile";

export default {
  components: {Icon, InputFile},
  template: `
   <div class="ld-textarea-wrapper">
       <label :for="id">{{label}}</label>
       <textarea @input="setInput" :id="id" :name="id" :placeholder="placeholder" :value="initialValue" :class="{ 'ld-has-file-input': fileAttachment }"></textarea>
       <InputFile  @fileInput="setFileInput" type="file" @error="handleError"/>
   </div>
`,
  props: {
    label: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    initialTextInput: {
      type: String,
      default: ''
    },
    fileAttachment: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      id: `ld-textarea-id-${Date.now()}`,
      fileInputID: `ld-file-textarea-input-id-${Date.now()}`,
      fileInput: "",
      initialValue: this.initialTextInput
    }
  },
  methods: {
    setInput(e) {
      this.$emit('setInput', e.target.value)
    },
    setFileInput(value) {
      this.fileInput = value
      this.$emit('setFileInput', value)
    },
    handleError(value) {
      console.log("HANDLE ERROR")
      console.log(value)
      this.$emit('error', value)
    }
  },
  mounted() {
    this.$emit('setInput', this.initialValue)
  }
}