
export default {
  components: {  },
  template: `
<div class="ld-feedback-text-replacement" > 
  
</div>
  `,
  props: {
    feedback: Object
  },
  data(){
    return {
    }
  },
  computed: {

  },
  methods: {

  },
  mounted(){

  }
}
