export default class MobileCursorClient {
  constructor() {
    this.targetElement = null
    this.clickPoint = {x: 0, y: 0}
    this.selectorType = "" // element || dot

    this.gateKeeper = {
      element: document.createElement('DIV'),
      hidden: true
    }

    this.confirmationBox = {
      element: document.createElement('DIV'),
      hidden: true,
      button: null,
      marker: null
    }

    this.functions = ["onTouchStart", "confirmClick"]
    this.init()
  }

  setSelectorType(type) {
    this.selectorType = type
    if (type === 'dot') {
      this.confirmationBox.marker.style.display = 'inline'
    } else if (type === 'element') {
      this.confirmationBox.marker.style.display = 'none'
    }
  }

  init() {
    // bind functions
    this.functions.forEach((fn) => (this[fn] = this[fn].bind(this)))

    // make confirmation box
    this.confirmationBox.marker = document.createElement('SPAN');
    this.confirmationBox.marker.classList.add('ld-marker')
    this.confirmationBox.button = document.createElement('BUTTON');
    this.confirmationBox.button.classList.add('ld-bordered')
    this.confirmationBox.button.innerText = 'add Feedback here'
    this.confirmationBox.element.appendChild(this.confirmationBox.marker)
    this.confirmationBox.element.appendChild(this.confirmationBox.button)
    this.confirmationBox.element.style.display = 'none'
    this.confirmationBox.element.classList.add('ld-mobile-feedback-modal')
    // this.confirmationBox.button.addEventListener('click', this.confirm)

    // make gatekeeper
    this.gateKeeper.element.classList.add('ld-mobile-gatekeeper')
    this.gateKeeper.element.style.display = 'none'

    // append element to parent
    const parentElement = document.querySelector('article.ld-selector-layer')
    if (parentElement) {
      parentElement.appendChild(this.gateKeeper.element)
      parentElement.appendChild(this.confirmationBox.element)
    }
  }

  showConfirmationBox() {
    // this.confirmationBox.classList.add(this.selectorType)
    this.confirmationBox.hidden = false
    this.confirmationBox.element.style.display = 'block'
  }

  hideConfirmationBox() {
    this.confirmationBox.hidden = true
    this.confirmationBox.element.style.display = 'none'
    // this.confirmationBox.classList.remove(this.selectorType)
  }

  showGateKeeper() {
    this.gateKeeper.hidden = false
    this.gateKeeper.element.style.display = 'block'
  }


  hideGateKeeper() {
    this.gateKeeper.hidden = true
    this.gateKeeper.element.style.display = 'none'
  }


  start(type) {
    if (type === 'dot' || type === 'element') {
      this.setSelectorType(type)
      window.addEventListener('touchstart', this.onTouchStart)
      this.showGateKeeper()
    }
  }

  stop() {
    window.removeEventListener('touchstart', this.onTouchStart)
    this.targetElement = null
    this.clickPoint = {x: 0, y: 0}
    this.selectorType = false
    this.hideGateKeeper()
  }

  repositionConfirmationBox() {
    if (this.confirmationBox.hidden) this.showConfirmationBox()
    this.confirmationBox.element.style.left = this.clickPoint.x + 'px'
    this.confirmationBox.element.style.top = this.clickPoint.y + 'px'
  }

  onTouchStart(e) {
    const touchedGateKeeper = e.target === this.gateKeeper.element
    const touchedConfirmationButton = e.target === this.confirmationBox.button

    if (touchedConfirmationButton) {
      this.confirmClick()
    } else if (touchedGateKeeper && e.touches && e.touches.length > 0) {
      // set click data
      const touchEvent = e.touches[0]
      this.clickPoint.x = touchEvent.clientX
      this.clickPoint.y = touchEvent.clientY
      this.getElement()

      // check if click is valid (it is always valid for type dot)
      const validClick = this.selectorType === 'dot' || (this.selectorType === 'element' && this.targetElement)

      // set confirmation box or hide it, based on validity of click
      if (validClick) this.repositionConfirmationBox()
      else this.hideConfirmationBox()

    } else {
      this.hideConfirmationBox()
    }
  }

  confirmClick() {
    this.triggerClick()
  }

  triggerClick() {
    window.EventBus.trigger('mobileClick', {
      x: this.clickPoint.x,
      y: this.clickPoint.y,
      targetElement: this.targetElement
    })
    this.hideConfirmationBox()
    this.hideGateKeeper()
    window.EventBus.trigger('mobileHoverElement', {
      result: null
    })
  }

  getElement() {
    const elementsFromPoint = document.elementsFromPoint(this.clickPoint.x, this.clickPoint.y)
    const loopdeskSection = document.querySelector('main.ld-main')
    const result = elementsFromPoint.find((element) => element.tagName !== "HTML" && element.tagName !== "BODY" && !loopdeskSection.contains(element));
    this.targetElement = result ? result : null

    window.EventBus.trigger('mobileHoverElement', {
      result: this.targetElement
    })
  }

}