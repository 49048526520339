const draftComponents = (titleContentReplacement = false, titleComment = false, titleCategory = false) => {
  return {
    contentReplacement: titleContentReplacement,
    comment: titleComment,
    category: titleCategory,
  }
}

export default {
  dot: {
    id: 'dot',
    marker: "marker-dot",
    position: "absolute",
    title: "Feedback anywhere" || "Feedback mit einem Punkt",
    description: "This type allows to add a mark anywhere on the page." || "Dieser Typ ermöglicht es, überall auf der Seite eine Bemerkung hinzufügen.",
    instruction: "Click on any position" || "Klicke auf eine beliebige Stelle",
    tutorialTitle: "Punctual feedback" || "Punktuelles Feedback",
    tutorialDescription: {
      'intro': "The dot selector allows you to place your feedback anywhere on the page." || "Damit lassen sich überall auf der Webseite Feedbacks geben. Einfach diesen Button auswählen und auf der Webseite den Punkt \"setzen\". Sobald dieser gesetzt wurde, kann ein Feedback gegeben werden.",
      'useCase': "If there is no element to select or your feedback concerns a very specific breakpoint, that's is the tool to chose from." || "Dieser Typ Feedback ist vor allem nützlich, wenn Browser-Fehler oder Responsive-Fehler auftreten.",
      'warning': "Please note that the feedback marker is only visible at the viewport width you set the feedback." || "Dieser Punkt ist nur bei der aktuellen Bildschirmseite sichtbar. Sobald sich die Bildschirmbreite ändert, wird der Punkt verschwinden.",
    },
    infoComponents: ['window-width', 'node-type'],
    draftFormComponents: draftComponents(false, "my feedback", "category"),
    draftTitle: "Type your comment",
    mobileSupport: true,
  },
  element: {
    id: 'element',
    marker: "marker-element",
    position: "elementRelated",
    title: "Chose an element" || "Ein Element auswählen",
    description: "This type allows you to select specific elements." || "Damit lassen sich wichtige Elemente auswählen.",
    instruction: "Click on an image, a box or a text element." || "Klicke auf ein Bild, eine Box oder ein Textelement",
    tutorialTitle: "Feedback on an element" || "Feedback auf ein Element",
    tutorialDescription: {
      'intro': "With that tool you can select specific elements (such as heading or images)." || "Damit lassen sich alle wichtigen Elemente anwählen und direktes Feedback zu geben.",
      'useCase': "This tool comes in handy if you want to feedback elements." || "Dieser Typ ist sehr praktisch, wenn z.B. auf einen Button oder auf einen Text gegeben wird.",
      'warning': null,
    },
    infoComponents: ['window-width', 'node-type'],
    draftFormComponents: draftComponents(false, "my feedback", "category"),
    draftTitle: "Feedback this element",
    mobileSupport: true,
  },
  content: {
    id: 'content',
    marker: "marker-element",
    position: "elementRelated",
    title: "Edit content" || "Inhalt bearbeiten",
    description: "Content changes can be entered directly on text and images." || "Textliche / Inhaltliche Änderungen können direkt eingegeben und vorgeschlagen werden.",
    instruction: "Click on an image oder a text element" || "Klicke auf ein Bild oder ein Textelement",
    tutorialTitle: "Edit content" || "Inhalt bearbeiten",
    tutorialDescription: {
      'intro': "This tool allows you to feedback text element or images." || "Damit kann direkt den Text bearbeitet eingesendet werden. Das ist vor allem nützlich, wenn Typos korrigiert werden sollen oder Texte angepasst werden. Zudem lassen sich auch Dateien hochladen und somit z.B. neue Bilder vorschlagen.",
      'useCase': "Chose this tool if you want to replace a text or an image" || "Dieser Typ ist vor allem nützlich, wenn inhaltliche Anpassungen gemacht werden müssen.",
      'warning': null,
    },
    infoComponents: ['window-width', 'node-type'],
    draftFormComponents: draftComponents('Content suggestion', "Comment", "category"),
    draftTitle: "Type your suggestion for this element",
    mobileSupport: false,
  },
}