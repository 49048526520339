import firebase from "../../services/firebase";
import domElementToObject from "../Utils/domElementToObject";


export default class Feedback {
  constructor() {
    this.id = null  // this is is only set if feedback is initiated from existing feedback or after feedback upload
    this.clientID = this.generateRandomString(12) // this id is used only for the client
    this.created = Date.now()
    this.isDraft = true
    this.isOpen = false
    this.version = 1
    this.domElement = {
      path: null,
      content: null,
      element: null,
      tagName: null,
    }
    this.pageInfos = {
      url: null,
      params: null,
      pageTitle: null,
      useragent: null
    }
    this.capture = {
      url: "",
      base64String: "",
      domContent: "",
      hash: this.generateRandomString(25)
    }
    this.feedbackType = null
    this.feedbacker = {
      name: null,
      email: null,
      role: null,
      isCurrentUser: true,
    }
    this.edits = {
      comment: {
        draft: "",
        saved: ""
      },
      file: {
        draft: "",
        saved: ""
      },
      textReplacement: {
        draft: "",
        saved: ""
      },
      imageReplacement: {
        draft: "",
        saved: ""
      },
      category: {
        draft: "",
        saved: ""
      }
    }
    this.position = {
      pageX: 0,
      pageY: 0,
      windowWidth: 0,
      windowHeight: 0,
      scrollX: 0,
      scrollY: 0
    }
  }

  initNewFeedback(x, y, windowWidth, windowHeight, scrollX, scrollY, feedbackType, elementPath) {
    this.setPosition(x, y, windowWidth, windowHeight, scrollX, scrollY)
    this.setRoute(window.location.href.split('?')[0], window.location.search)
    this.feedbackType = feedbackType
    this.domElement.path = elementPath
    this.version = 1
    this.setDomElementFromPath()
  }

  initFromExistingFeedback(obj, feedbackType, isCurrentUser) {
    this.id = obj.id
    this.domElement.path = obj.dom_element_path
    this.capture.url = obj.capture_url
    this.isDraft = false
    this.feedbackType = feedbackType
    this.created = obj.created
    this.version = 1
    this.setPosition(obj.position_page_x, obj.position_page_y, obj.window_width, obj.window_height, obj.scroll_position_x, obj.scroll_position_y)
    this.setRoute(obj.url, obj.params)
    this.setEdits(obj.feedback_comment, obj.feedback_file, obj.feedback_text_replacement, obj.feedback_image_replacement, 'hidden')
    this.setFeedbacker(obj.feedback_username, obj.feedback_email, "", isCurrentUser)

    if (this.feedbackType.position === "elementRelated") {
      this.setDomElementFromPath()
    }
  }

  setDomElementFromPath() {
    const element = domElementToObject.decode(this.domElement.path, this.version)
    if (element) this.setElementValues(element)
  }

  setElementValues(element) {
    this.domElement.element = element
    this.domElement.tagName = element.tagName.toLowerCase()
    if (this.domElement.tagName === 'img') {
      this.domElement.content = [{
        childIndex: 0,
        text: element.src,
        type: 'imageURL'
      }]
    } else {
      this.domElement.content = []
      const childNodesArray = Array.from(element.childNodes)
      childNodesArray.forEach(({textContent}, index) => {
        if (textContent && textContent !== '') {
          this.domElement.content.push({
            childIndex: index,
            text: textContent,
            type: 'text'
          })
        }
      })
    }
  }

  getInfoComponents() {
    return this.feedbackType.infoComponents
  }

  getDraftComponents() {
    return this.feedbackType.draftFormComponents
  }

  getDraftTitle() {
    return this.feedbackType.draftTitle
  }

  getFeedbackType() {
    return this.feedbackType
  }

  setRoute(url, params) {
    this.pageInfos = {
      url: url || "",
      params: params || "",
      pageTitle: document.title,
      useragent: navigator.userAgent
    }
  }

  resetEdits() {
    this.edits.file.draft = this.edits.file.saved
    this.edits.comment.draft = this.edits.comment.saved
    this.edits.textReplacement.draft = this.edits.textReplacement.saved
    this.edits.category.draft = this.edits.category.saved
    this.edits.imageReplacement.draft = this.edits.imageReplacement.saved
  }

  setEdits(comment, file, textReplacement, imageReplacement, category) {
    this.edits.comment.draft = comment
    this.edits.comment.saved = comment
    this.edits.file.saved = file
    this.edits.textReplacement.saved = textReplacement
    this.edits.category.saved = category
    this.edits.imageReplacement.saved = imageReplacement
  }

  setFeedbacker(name, email, role, isCurrentUser) {
    this.feedbacker = {
      name,
      email,
      role,
      isCurrentUser,
    }
  }

  setPosition(pageX, pageY, windowWidth, windowHeight, scrollX, scrollY) {
    this.position = {
      pageX,
      pageY,
      windowWidth,
      windowHeight,
      scrollX,
      scrollY,
    }
  }

  updateCurrentUser(user) {
    this.feedbacker.isCurrentUser = user ? this.feedbacker.email === user.email : false
  }


  generateRandomString(count = 12) {
    let ID = "";
    let characters = "0123456789";
    for (let i = 0; i < count; i++) ID += characters.charAt(Math.floor(Math.random() * 10));
    return ID;
  }

  replaceEditsFromDraftToSaved() {
    this.edits.comment.saved = this.edits.comment.draft
    this.edits.file.saved = this.edits.file.draft
    this.edits.textReplacement.saved = this.edits.textReplacement.draft
    this.edits.imageReplacement.saved = this.edits.imageReplacement.draft
  }

  async update(options) {

    return new Promise(async (resolve, reject) => {
      const archive = options ? options.archive || false : false
      const edits = {
        id: this.id,
        key: firebase.getKey(),
        feedback_comment: this.edits.comment.draft,
        feedback_text_replacement: this.edits.textReplacement.draft,
        feedback_image_replacement: this.edits.imageReplacement.draft,
        feedback_file: this.edits.file.draft,
        position_page_x: this.position.pageX,
        position_page_y: this.position.pageY,
        window_width: this.position.windowWidth,
        window_height: this.position.windowHeight,
        scroll_position_y: this.position.scrollY,
        scroll_position_x: this.position.scrollX,
        is_archived: archive,
      }

      await firebase.updateFeedback(JSON.stringify(edits))
        .then((res) => {
          console.log("feedback updated")
          this.replaceEditsFromDraftToSaved()
          resolve()
        })
        .catch((err) => {
          console.log("error while update feedback")
          reject()
        })
    })
  }

  async upload() {
    const jsonData = this.getDataAsJSON()
    const queries = {
      ww: this.position.windowWidth,
      wh: this.position.windowHeight,
      sx: this.position.scrollX,
      sy: this.position.scrollY,
      url: this.pageInfos.url,
      hash: this.capture.hash,
      dpr: window.devicePixelRatio
    }

    firebase.executeCaptureFeedback(this.currentDocument(), queries)

    return new Promise(async (resolve, reject) => {
      await firebase.uploadFeedback(jsonData)
        .then((body) => {
          this.id = body.feedback && body.feedback.id ? body.feedback.id : ""
          this.isDraft = false
          this.replaceEditsFromDraftToSaved()
          resolve({
            newFeedbackCount: body.plan.uploadedCount
          })
        })
        .catch(() => {
          console.log("Feedback not uploaded")
          reject()
        })
    })
  }


  currentDocument() {
    const loopdeskSection = document.querySelector('section.ld-section')
    loopdeskSection.classList.add('ld-capture-mode')
    const documentHTML = document.documentElement.outerHTML
    loopdeskSection.classList.remove('ld-capture-mode')
    return documentHTML
  }


  getDataAsJSON() {
    const feedbackObject = {
      "key": firebase.getKey(),
      "position_page_x": this.position.pageX,
      "position_page_y": this.position.pageY,
      "feedback_username": this.feedbacker.name,
      "feedback_email": this.feedbacker.email,
      "params": this.pageInfos.params,
      "scroll_position_x": this.position.scrollX,
      "scroll_position_y": this.position.scrollY,
      "page_title": this.pageInfos.pageTitle,
      "feedback_type": this.feedbackType.id,
      "url": this.pageInfos.url,
      "user_agent": this.pageInfos.useragent,
      "window_width": this.position.windowWidth,
      "window_height": this.position.windowHeight,
      "dom_element_path": this.domElement.path,
      "dom_element_content": this.domElement.content,
      "document_html": "null",
      "v": this.version,
      "feedback_comment": this.edits.comment.draft,
      "feedback_text_replacement": this.edits.textReplacement.draft,
      "feedback_image_replacement": this.edits.imageReplacement.draft,
      "feedback_file": this.edits.file.draft,
      "capture_url": this.capture.base64String || "null",
      "capture_hash": this.capture.hash || "null",
      "category": this.edits.category.draft,
    }
    console.log(feedbackObject)
    return JSON.stringify(feedbackObject)
  }
}

