import InputText from "../../Utilities/InputText";
import InputDropdown from "../../Utilities/InputDropdown";

export default {
  components: {InputText, InputDropdown},
  template: `
<form class="ld-sign-in"> 
  <InputText label="name" @setInput="setName" :initialValue="currentUser.name" />
  <InputText label="email" @setInput="setEmail" :initialValue="currentUser.email"/>
  <InputDropdown label="role" :options="fields.role.options" @setInput="setRole" :initialValue="currentUser.role"/>
  <div class="lf-form-errors">
    <p v-show="errors.invalidInputs">Some of the fields contain invalid values</p>
  </div>
</form>
  `,
  data() {
    return {
      currentUser: this.$root.getUser(),
      project: this.$root.getProject(),
      validForm: false,
      errors: {
        invalidInputs: false
      },
      fields: {
        name: {
          value: "",
          type: "input",
          invalid: true,
        },
        email: {
          value: "",
          type: "input",
          invalid: true,
        },
        role: {
          value: "",
          type: "dropdown",
          options: ['user', 'designer', 'developer', 'website owner'],
          invalid: false,
        }
      },
    }
  },
  props: {},
  computed: {},
  watch: {
    validForm(isValid) {
      this.$emit('formValidUpdate', isValid)
    }
  },
  methods: {
    setName(value) {
      this.fields.name.value = value
      this.fields.name.invalid = value === ""
      this.checkFormValidity()
    },
    setEmail(value) {
      this.fields.email.value = value
      this.fields.email.invalid = !this.testEmail(value)
      this.checkFormValidity()
    },
    setRole(value) {
      this.fields.role.value = value
      this.checkFormValidity()
    },
    testEmail(email) {
      const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
      return emailPattern.test(email);
    },
    checkFormValidity() {
      this.validForm = Object
        .values(this.fields)
        .every(({invalid}) => !invalid)

      if (this.validForm) {
        this.project.updateUser(this.fields.name.value, this.fields.email.value, this.fields.role.value)
        this.$root.updateFeedbackFeedbacker()
      }
    }

  },
  mounted() {
  }

}
