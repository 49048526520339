export default {
  components: {},
  template: `
<div class="ld-feedback-card-info" > 
  <p v-if="getInfo()" v-html="getInfo()"></p>
</div>
  `,
  props: {
    feedback: Object,
  },
  data() {
    return {
      isCurrentUser: this.feedback.feedbacker.isCurrentUser
    }
  },
  methods: {
    getInfo() {
      return `This feedback was set ${this.getWindowInfo()} by <b>${this.isCurrentUser ? 'you' : this.feedback.feedbacker.name}</b> ${this.getDateInfo()}.`
    },
    getWindowInfo() {
      if (this.feedback.feedbackType && this.feedback.feedbackType.infoComponents.includes('window-width')) {
        return `at a window width of <b>${this.feedback.position.windowWidth}px</b>`
      }
      return ""
    },
    getDateInfo(){
      const created = this.feedback.created || false
      const date = new Date(created)
      if (date) {
        const dateString = date.toLocaleDateString()
        const timeString = date.toLocaleTimeString().substring(0, 5)
        return `on the ${dateString} at ${timeString}`
      }
      return ""
    }
  },
  mounted() {

  }
}
