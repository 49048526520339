import encodingMethods from "../Constants/encodingMethods";

export default {

  currentVersionID: 1,

  getMethodByVersion: (versionID, currentVersionID = 1) => {
    const version = versionID ? versionID : currentVersionID
    return "getDomElementByIndexAndTag"
  },

  encode: function (element, versionID) {
    const version = this.getMethodByVersion(versionID)
    return encodingMethods[version].encode(element)
  },

  decode: function (element, versionID) {
    const version = this.getMethodByVersion(versionID)
    return encodingMethods[version].decode(element)
  },
}