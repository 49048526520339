export default {
  template: `
   <div class="ld-input-wrapper">
       <label :for="id" >{{label}}</label>
       <input @input="setInput" :type="type" :id="id" :name="id" :value="initialValue" :placeholder="placeholder" maxlength="150"/>
   </div>
`,
  props: {
    label: {
      type: String,
      default: ''
    },
    initialValue: {
      type: String,
      default: 'type here'
    },
    placeholder: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'text'
    },
  },
  data() {
    return {
      id: `ld-input-${this.type}-id-${Date.now()}`
    }
  },
  methods: {
    setInput(e) {
      this.$emit('setInput', e.target.value)
    }
  },
  mounted() {
    this.$emit('setInput', this.initialValue)
  }
}