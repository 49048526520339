import FeedbackItem from "./Feedback/FeedbackItem";

export default {
  components: {FeedbackItem},
  template: `
<article class="ld-feedback-layer" :class="{'open-feedbacks': openFeedbacks }"> 
   <FeedbackItem 
        v-for="feedback in feedbacks" :key="feedback.clientID" 
        :hidden="feedbacksVisible ? false : !feedback.isDraft"
        :windowWidth="windowWidth" :feedback="feedback" 
        @open="toggleFreeze" :forceClose="forceClose"/>
</article>
  `,
  props: {
    forceClose: Number,
  },
  data() {
    return {
      openFeedbacks: false,
      windowWidth: window.innerWidth,
    }
  },
  computed: {
    feedbacks() {
      return this.$root.getFeedbacks();
    },
    feedbacksVisible(){
      return this.$root.getFeedbacksVisibility();
    }
  },
  methods: {
    toggleFreeze(freeze = true) {
      this.openFeedbacks = freeze
    },
  },
  mounted() {
    this.$root.loadFeedbacks()
    window.onresize = () => this.windowWidth = window.innerWidth
  }
}
