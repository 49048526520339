export default {
  components: {},
  template: `
<div class="ld-feedback-delete-area">
    <button class="ld-unfilled" @click="archiveFeedback">Delete Feedback</button>
</div>
  `,
  props: {
    feedback: Object,
  },
  data() {
    return {}
  },
  computed: {},
  watch: {},
  methods: {
    archiveFeedback() {
      this.feedback.update({
        archive: true
      })
      this.$root.deleteFeedback(this.feedback.clientID)
    }
  },
  mounted() {

  }
}
