
export default {
  components: {},
  template: `
<div class="ld-toolbar-block">
    <p class="block-title">Other options</p>
   <button class="ld-bordered" @click="leave">Leave Loopdesk</button>
</div>
  `,
  props: {
  },
  data() {
    return {
    }
  },
  computed: {
  },
  methods: {
    leave(){
      window.EventBus.trigger('leaveLoopdesk')
    }
  },
  mounted() {
  }
}
