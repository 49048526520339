import Project from "../constructors/Project/Project";
import sourceOfTruth from "./../../state-management"
import firebase from "./firebase";
import Billboard from "../constructors/Utils/Billboard";

const CryptoJS = require("crypto-js");


export default {

  initKey: function () {
    this.privateKey = this.getPrivateKey()
    this.publicKey = this.getPublicKey()
  },

  check: async function () {
    this.initKey()
    firebase.setKey(this.privateKey)

    return new Promise(async (resolve, reject) => {

      if (this.privateKey && this.publicKey) { // IF BOTH KEYS PROVIDED

        // RETURN DEFAULT PROJECT
        if (process.env.NODE_ENVIRONMENT === 'development' && false) {
          const projectInstance = new Project(this.defaultProject.loop, this.defaultProject.plan, this.privateKey)
          projectInstance.init()
            .then(() => {
              sourceOfTruth.setProject(projectInstance)
              resolve()
            })
            .catch((err) => {
              reject("rejecting for no reason")
            })
        } else {

          // EXECUTE REAL CHECK KEY
          Billboard.write('checking keys')
          await firebase.checkKey(
            {public: this.publicKey, private: this.privateKey},
            (project) => {
              const projectInstance = new Project(project.loop, project.plan, this.privateKey)
              projectInstance.init()
                .then(() => {
                  sourceOfTruth.setProject(projectInstance)
                  resolve()
                })
                .catch(() => {
                  reject("Project could not be created")
                })
            },
            () => {
              reject("The password you provided is wrong")
            }
          )
        }
      }
      // } else { // IF A KEY IS MISSING -> nothing should happen here
      //   reject('key-missing')
      // }
    })
  },

  getError(error) {
    console.log(error)
  },


  defaultProject: {
    "msg": "allowed",
    "project_color": "#00000",
    "loop": {
      "name": "loop-name xy",
      "key": "loop-key"
    },
    "plan": {
      "id": "4882402e8ef4690452b74a0b204b43d0a4a063cd7b24b211fa670818becb532a",
      //"id": "sdfsdf",
      "uploadedCount": 10,
      "maximalCount": 100
    }
  },

  encrypt: (string) => {
    if (string) return CryptoJS.AES.encrypt(string, 'somesecretkey').toString()
    return ""
  },

  decrypt: (string) => {
    if (string) return CryptoJS.AES.decrypt(string, 'somesecretkey').toString(CryptoJS.enc.Utf8)
    return false
  },

  getPrivateKey: function () {
    const urlKey = this.getQueries().loopdesk || false
    const storedKey = localStorage.getItem('loopdesk.key') ? this.decrypt(localStorage.getItem('loopdesk.key')) : false

    const rawKey = urlKey || storedKey || false

    if (rawKey) { // save encrypted key in LS
      localStorage.setItem('loopdesk.key', this.encrypt(rawKey))
    }

    if (urlKey) { // delete key in URL
      window.history.pushState({}, document.title, window.location.pathname);
    }

    return rawKey
  },

  getPublicKey: function () {
    const script = document.querySelector("SCRIPT[loopdesk]")
    return script ? script.getAttribute('loopdesk') || false : false
  },

  getQueries: function () {
    const urlSearchParams = new URLSearchParams(window.location.search);
    return Object.fromEntries(urlSearchParams.entries());
  },


  privateKey: false,
  publicKey: false,


}

