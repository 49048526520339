const lerp = (a, b, n) => (1 - n) * a + n * b;

export default class CursorClient {
  constructor() {
    this.target = {x: 0.5, y: 0.5}; // mouse position
    this.cursor = {x: 0.5, y: 0.5}; // cursor position
    this.elementsFromPoint = null
    this.speed = 0.2;
    this.hoveredElement = null
    this.hoveringHistory = [null, null, null, null, null, null, null, null, null]

    this.init();
  }

  stop() {
    this.hoveredElement = null
    window.removeEventListener("mousemove", this.onMouseMove);
  }

  start() {
    window.addEventListener("mousemove", this.onMouseMove);
    this.raf = requestAnimationFrame(this.render);
  }

  bindAll() {
    ["onMouseMove", "render"].forEach((fn) => (this[fn] = this[fn].bind(this)));
  }

  onMouseMove(e) {
    //get normalized mouse coordinates [0, 1]

    this.target.x = e.clientX / window.innerWidth;
    this.target.y = e.clientY / window.innerHeight;

    // get hovered Element that is not child of loopdesk
    this.elementsFromPoint = document.elementsFromPoint(e.clientX, e.clientY)
    if (this.elementsFromPoint.length > 0) this.getElement()

    // trigger loop if no loop is active
    if (!this.raf) this.raf = requestAnimationFrame(this.render);
  }

  getElement() {
    const parent = document.querySelector('main.ld-main')
    const result = this.elementsFromPoint.find((element) => element.tagName !== "HTML" && element.tagName !== "BODY" && !parent.contains(element));
    this.hoveringHistory.push(result)
    this.hoveringHistory = this.hoveringHistory.slice(1, 10)

    if (this.hoveringHistory.every(it => it === result)) {
      if (this.hoveredElement !== result) {
        this.hoveredElement = result
        window.EventBus.trigger('hoverValidElement', {result})
      }
    }

  }

  render() {
    //calculate lerped values
    this.cursor.x = lerp(this.cursor.x, this.target.x, this.speed);
    this.cursor.y = lerp(this.cursor.y, this.target.y, this.speed);
    document.documentElement.style.setProperty("--cursor-x", this.cursor.x);
    document.documentElement.style.setProperty("--cursor-y", this.cursor.y);

    // this.elementsFromPoint = document.elementsFromPoint(this.cursor.x * window.innerWidth, this.cursor.y * window.innerHeight)
    // if (this.elementsFromPoint.length > 0) this.getElement()


    //cancel loop if mouse stops moving
    const delta = Math.sqrt(
      Math.pow(this.target.x - this.cursor.x, 2) +
      Math.pow(this.target.y - this.cursor.y, 2)
    );
    if (delta < 0.001) {
      cancelAnimationFrame(this.raf);
      this.raf = null;
      return;
    }
    //or continue looping if mouse is moving
    this.raf = requestAnimationFrame(this.render);
  }

  init() {
    this.bindAll();
  }
}

