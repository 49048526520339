import ToolbarSelectors from "./ToolbarBlocks/ToolbarSelectors"
import ToolbarSettingsAppearance from "./ToolbarBlocks/ToolbarSettingsAppearance";
import ToolbarSettingsHelp from "./ToolbarBlocks/ToolbarSettingsHelp";
import ToolbarInfos from "./ToolbarBlocks/ToolbarInfos";
import ToolbarToggle from "./ToolbarBlocks/ToolbarToggle";
import ToolbarLeave from "./ToolbarBlocks/ToolbarLeave";

export default {
  components: {
    ToolbarToggle,
    ToolbarLeave,
    ToolbarSelectors,
    ToolbarSettingsAppearance,
    ToolbarInfos,
    ToolbarSettingsHelp
  },
  template: `
<main class="ld-toolbar-main" :class="{'expanded': expanded}">
    <ToolbarToggle @expand="expandToolbar" :expanded="expanded"/>
    <ToolbarSelectors @closeToolbar="expandToolbar(false)" />
    <ToolbarSettingsAppearance />
    <ToolbarSettingsHelp />
    <ToolbarInfos />   
    <ToolbarLeave v-if="isMobile"/>
</main>
  `,
  data() {
    return {
      expanded: false,
    }
  },
  computed: {
    project() {
      return this.$root.getProject()
    },
    isMobile() {
      return this.$root.getMobileView()
    },
  },
  methods: {
    expandToolbar(value) {
      this.expanded = value
    },
  },
}
