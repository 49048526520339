export default class TwinObserver {
  constructor(elementOrigin, elementTwin, id) {
    this.elementOrigin = elementOrigin
    this.elementTwin = elementTwin
    this.id = id
    this.stopped = false
    this.config = {
      childList: true,
      attributes: true,
      subtree: true,
      characterData: true
    }
    this.setMutationObserver()
  }

  init() {
    return new Promise((resolve, reject) => {
      if (this.elementTwin && this.elementOrigin) {
        this.setClassNames()
        this.copyPosition()
        this.listenToWindowResize()
        resolve("twin observer initialised")
      } else {
        reject("Either origin or twin is not an element")
      }
    })

  }

  setMutationObserver() {
    const _this = this

    if (this.elementOrigin) {

      this.observer = new MutationObserver(function (mutations) {
        mutations.forEach(function (mutation) {
          _this.copyPosition()
        });
      })

      this.observer.observe(this.elementOrigin, this.config)
    }
  }

  setClassNames() {
    this.elementOrigin.classList.add('ld-selected-component')
    this.elementOrigin.classList.add(`ld-id-${this.id}`)
  }

  stop() {
    this.stopped = true
    this.observer.disconnect()
    this.elementOrigin.classList.remove('ld-selected-component')
    this.elementOrigin.classList.remove(`ld-id-${this.id}`)
  }

  copyPosition() {
    const boundingBox = this.elementOrigin.getBoundingClientRect()
    this.elementTwin.style.top = boundingBox.top + window.scrollY + 'px'
    this.elementTwin.style.left = boundingBox.left + window.scrollX + 'px'
    this.elementTwin.style.width = boundingBox.width + "px"
    this.elementTwin.style.height = boundingBox.height + "px"

    // prevent overflowing elements
    if (boundingBox.left > window.innerWidth) {
      this.elementTwin.style.display = "none"
    }
  }


  listenToWindowResize() {
    window.addEventListener('resize', () => {
      if (!this.stopped) this.copyPosition()
    })
  }

}