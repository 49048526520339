import LaunchInstruction from "./InstructionBlocks/LaunchInstruction";
import ToolbarInstruction from "./InstructionBlocks/ToolbarInstruction";
import SelectorInstruction from "./InstructionBlocks/SelectorInstruction";
import Icon from "../Utilities/Icon";

export default {
  components: {SelectorInstruction, LaunchInstruction, ToolbarInstruction, Icon},
  template: `
<div class="ld-overview-instructions">  
    <header>
          <Icon />
          <p>Loopdesk allows the feedback of a complete website. There are several feedback tools available for this purpose. This tutorial explains how this application works and how to use these tools. </p>
    </header> 
    
    <main>
        <LaunchInstruction />
        <ToolbarInstruction />   
        <SelectorInstruction />
        <button class="ld-filled ld-close-button"  @click="closeInstructions">Close</button>

    </main>


</div>
  `,
  data() {
    return {}
  },
  computed: {},
  methods: {
    closeInstructions() {
      this.$emit('close')
    },
  },
  mounted() {

  }
}
