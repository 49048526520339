import {body} from "../../constants";

class RouteWatcher {

  constructor() {
    this.currentHref = document.location.href
    this.observer = new MutationObserver((e) => this.callback(e))
    this.event = new Event("routeChange");
    this.config = {
      childList: true,
      subtree: true
    }
  }

  initObserver(){
    this.observer.observe(body, this.config);
  }

  callback(mutations){
    for (const mutation of mutations) {
      if (this.currentHref !== document.location.href) {
        document.dispatchEvent(this.event)
        this.currentHref = document.location.href;
      }
    }
  }
}

export default new RouteWatcher()