export default {
  template: `
    <figure class="ld-icon" :class="classList">
      <img :src="source" />
    </figure>   
`,
  props: {
    icon: {
      type: String,
      default: 'logo'
    },
    classList: {
      type: String,
      default: []
    }
  },
  watch: {
    icon(value) {
      this.source = `${process.env.NODE_BASE_URL}assets/icons/${value}.svg`
      // this.source = `https://cdn-dev.loopdesk.io/assets/icons/${value}.svg`
    }
  },
  data() {
    return {
      source: `${process.env.NODE_BASE_URL}assets/icons/${this.icon}.svg`,
      // source: `https://cdn-dev.loopdesk.io/assets/icons/${this.icon}.svg`,
    }
  },
  methods: {},
  mounted() {

  }
}
