import Icon from "../../Utilities/Icon";

export default {
  components: {Icon},
  template: `
<div class="ld-toolbar-block toggle">
    <p class="block-title">Appearance</p>
    <div v-for="setting in settings" :key="setting.id" @click="callFunction(setting)" class="ld-setting-wrapper">
        <button class="ld-unfilled ld-iconed" :class="{'hidden-mobile': !setting.mobileSupport}">
            <Icon :icon="setting.toggled ? setting.icon.on : setting.icon.off"/>
            <span>{{ setting.toggled ? setting.title.on : setting.title.off }}</span>
        </button>
    </div>
</div>
  `,
  props: {
    title: {
      type: String,
      default: "Settings"
    }
  },
  data() {
    return {
      settings: [
        {
          toggled: localStorage.getItem('loopdesk.theme') === 'theme--dark',
          id: 'toggleTheme',
          title: {
            on: "Switch to light mode",
            off: "Switch to dark mode",
          },
          icon: {
            on: "light-mode",
            off: "dark-mode",
          },
          function: () => this.toggleTheme(),
          onToggle: function () {
            this.toggled = !this.toggled
            this.function()
          },
          mobileSupport: true,
        },
        {
          toggled: this.$root.getFeedbacksVisibility(),
          id: 'visibilityFeedbacks',
          title: {
            on: "Hide feedbacks",
            off: "Show feedbacks",
          },
          icon: {
            on: "visibility-off",
            off: "visibility-on",
          },
          function: () => this.toggleFeedbacksVisibility(),
          onToggle: function () {
            this.toggled = !this.toggled
            this.function()
          },
          mobileSupport: false,
        },
      ]
    }
  },
  computed: {},
  methods: {
    toggleTheme() {
      const loopdeskSection = document.querySelector('section.ld-section')
      if (loopdeskSection) {
        const currentTheme = loopdeskSection.classList.contains('theme--dark') ? 'theme--dark' : 'theme--light'
        const newTheme = currentTheme === 'theme--light' ? 'theme--dark' : 'theme--light'
        localStorage.setItem('loopdesk.theme', newTheme)
        loopdeskSection.classList.remove(currentTheme)
        loopdeskSection.classList.add(newTheme)
      }
    },
    toggleFeedbacksVisibility() {
      this.$root.toggleFeedbacksVisibility()
    },
    callFunction(setting) {
      setting.onToggle()
    }
  },
}
