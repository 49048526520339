import Icon from "../Utilities/Icon";
export default {
  components: { Icon },
  template: `
<header class="ld-intro-modal-header"> 
    <Icon />
</header>
  `,
  data() {
    return {
    }
  },
  props: {

  },
  computed: {

  },
  methods: {
  },
  mounted() {
  }

}
