export default {
  template: `
    <figure class="ld-icon-toggle" @click="emitToggle" :class="{ toggled }">
      <img :src="source" />
    </figure>   
`,
  props: {
    icon: {
      type: String,
      default: 'logo'
    },
    toggled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      baseUrl: process.env.NODE_BASE_URL,
      source: `${process.env.NODE_BASE_URL}assets/icons/${this.icon}.svg`
    }
  },
  methods: {
    emitToggle(){
      this.$emit('toggle')
    }
  },
}
