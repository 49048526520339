import ToolbarFooter from "./Toolbar/ToolbarFooter";
import ToolbarHeader from "./Toolbar/ToolbarHeader";
import ToolbarMain from "./Toolbar/ToolbarMain";
import Toggle from "./Utilities/Toggle";

export default {
  components: {ToolbarFooter, ToolbarHeader, ToolbarMain, Toggle},
  template: `
<article class="ld-toolbar" :class="{ expanded, 'hidden' : toolbarHidden }">
  <ToolbarHeader @leaveLoopdesk="leaveLoopdesk" @minimizeLoopdesk="minimizeLoopdesk"/>
  <ToolbarMain />
  <ToolbarFooter :expanded="expanded"/>
</article>
  `,
  data() {
    return {
      expanded: false,
      feedbacksHidden: false,
      toolbarHidden: false,
    }
  },
  computed: {
    feedbackMode() {
      return this.$root.getActiveFeedbackMode()
    },
    isFeedbacking() {
      return this.$root.getIsFeedbacking()
    }
  },
  watch: {
    isFeedbacking(newValue) {
      this.toolbarHidden = newValue
    }
  },
  methods: {
    leaveLoopdesk() {
      this.$emit('leave')
    },
    minimizeLoopdesk() {
      this.$emit('minimize')
    },
    toggleExpanded() {
      this.expanded = !this.expanded
    },
  },
  mounted() {
  }
}
