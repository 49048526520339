import ImageElement from "../../Utilities/Image";
import feedbackData from "../../../globals/constructors/Constants/feedbackData";

export default {
  components: {ImageElement},
  template: `
<div class="ld-instruction-block">   
   <h4><span class="ld-info-title">Choose the right feedback tool</span> </h4> 
   <div class="ld-grid" v-for="({id, title, tutorialDescription}) in feedbackData">
      <div>
         <b>{{ title }}</b>
         <p>{{ tutorialDescription.intro }}</p>
         <b>Use Case</b>
         <p>{{ tutorialDescription.useCase }}</p>
         <p>{{ tutorialDescription.warning }}</p>
      </div>
      <ImageElement :path="'tutorial/instructions-' + id + '-selector'" filetype="jpg"/>
  </div>


</div>
  `,
  data() {
    return {
      feedbackData: feedbackData
    }
  },
  computed: {},
  methods: {},
  mounted() {

  }
}
