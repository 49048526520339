import OverviewInstructions from "./Help/OverviewInstructions";

export default {
  components: {OverviewInstructions},
  template: `
<article class="ld-help" v-if="hasOpenViews" @click.self="closeCurrentView">   
  <div class="ld-help-wrapper">
       <OverviewInstructions @close="openInstructions(false)" v-if="openViews.overviewInstructions"/>
  </div>
</article>
  `,
  data() {
    return {
      openViews: {
        overviewInstructions: false
      }
    }
  },
  computed: {
    hasOpenViews() {
      return Object.values(this.openViews).some(key => key)
    }
  },
  methods: {
    closeCurrentView(){
      this.openViews = {
        overviewInstructions: false
      }
    },
    openInstructions(open = true) {
      this.openViews.overviewInstructions = open
    }
  },
  mounted() {
    window.EventBus.on('openOverviewInstructions', () => {
      this.openInstructions()
    })
  }
}
