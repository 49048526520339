import {html} from "./globals/constants";
import Intro from './components/Intro'
import Help from './components/Help'
import Launch from './components/Launch'
import Toolbar from './components/Toolbar'
import FeedbackLayer from './components/FeedbackLayer'
import SelectorLayer from "./components/SelectorLayer";


export default {
  components: {Toolbar, Intro, FeedbackLayer, Launch, Help, SelectorLayer},
  template: `
<main class="ld-main" :data-loopdesk-launched="launched ? 'true' : 'false'">
  <Intro v-if="showIntro === 1" @removeIntro="removeIntro"/>
  <Toolbar v-show="launched"  @minimize="launchLoopdesk(false)" />
  <Launch />
  <FeedbackLayer :forceClose="closeFeedbacks"/>
  <Help />
  <SelectorLayer @newFeedback="addFeedback"/>
</main>
  `,
  data() {
    return {
      // showIntro: true,
      showIntro: 0,
      launched: false,
      inFeedbackMode: false,
      help: false,
      clickPath: [],
      closeFeedbacks: 0,
    }
  },
  computed: {
    activeFeedbackMode() {
      return this.$root.getActiveFeedbackMode()
    },
  },
  watch: {
    activeFeedbackMode(mode) {
      if (mode) {
        this.enterFeedbackMode(mode)
      } else {
        this.leaveFeedbackMode()
      }
    }
  },
  methods: {
    launchLoopdesk(launch = true) {
      // check if there is a user and set count of showIntro to 2 if true
      if (this.$root.getHasUser()) this.showIntro = 2
      // increase count of show intro
      if (this.showIntro < 2) this.showIntro++
      this.launched = launch
      this.closeAllFeedback()
    },
    removeIntro() {
      this.showIntro = 2
    },
    closeAllFeedback() {
      this.closeFeedbacks++
    },
    enterFeedbackMode(mode) {
      this.inFeedbackMode = true
      this.closeAllFeedback()
      html.setAttribute('data-loopdesk-feedback-mode', mode)
    },
    leaveFeedbackMode() {
      this.inFeedbackMode = false
      this.$root.setFeedbackMode(null)
      html.removeAttribute('data-loopdesk-feedback-mode')
    },
    initEscKeyEvent() {
      const _this = this
      document.onkeydown = function (event) {
        if (event.defaultPrevented) return
        if (event.key === 'Escape' && _this.inFeedbackMode) _this.leaveFeedbackMode()
      }
    },
    async addFeedback(fb) {
      this.$root.createFeedback(fb.pageX, fb.pageY, window.innerWidth, window.innerHeight, window.scrollX, window.scrollY, fb.path)
      this.leaveFeedbackMode()
    },
    appendExistingFeedbacks() {
      const feedbacks = this.$root.state.feedbacks
      if (feedbacks && feedbacks.length > 0) {
      }
    }
  },
  mounted() {
    this.initEscKeyEvent()
    this.appendExistingFeedbacks()
    if (this.$root.getMobileView()) this.launched = true
    window.EventBus.on('launchLoopdesk', () => this.launchLoopdesk())
  }
}
