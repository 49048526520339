import Icon from "../Utilities/Icon";
import {html} from "../../globals/constants"

export default {
  components: {Icon},
  template: `
<header class="ld-toolbar-header" @mousedown="initDrag">
    <Icon icon="drag-handle" />
    <Icon :classList="['ld-logo']"/>
    <span></span>
    <button class="ld-wrapper-minimize-loodespk" @click="requestMinimizeLoopdesk">
        <Icon  icon="btn-minimize"/>
    </div> 
</header>
  `,
  props: {},
  data() {
    return {
      inDrag: false,
      toolbar: null,
      posXinit: 500,
      posYinit: 300,
      toolbarWidth: 0,
      toolbarHeight: 0,
      dragYOffset: 0,
      dragXOffset: 0,
    }
  },
  computed: {
    isMobile(){
      return this.$root.getMobileView()
    }
  },
  methods: {
    requestMinimizeLoopdesk() {
      this.$emit('minimizeLoopdesk')
    },
    getBoundedValues(x = Number, min = 0, max = Number) {
      return x < min ? min : x > max ? max : x
    },
    dragEvent(e) {
      const x = e.clientX - this.dragXOffset
      const y = e.clientY - this.dragYOffset
      this.setToolbarPosition(this.getBoundedValues(x, 0, window.innerWidth - 200), this.getBoundedValues(y, 0, window.innerHeight - 100))
    },
    dragObject() {
      window.addEventListener('mousemove', this.dragEvent)
    },
    setToolbarPosition(x = -1, y = -1) {
      if (x >= 0) this.toolbar.style.left = x + 'px'
      if (y >= 0) this.toolbar.style.top = y + 'px'
    },
    endDrag() {
      window.removeEventListener('mousemove', this.dragEvent)
      this.inDrag = false
      html.removeAttribute('event')
    },
    initDrag(e) {
      if(this.isMobile) return
      this.inDrag = true
      this.dragYOffset = e.clientY - this.toolbar.getBoundingClientRect().top // difference between top of toolbar and drag Position Y
      this.dragXOffset = e.clientX - this.toolbar.getBoundingClientRect().left // difference between left of toolbar and drag Position X
      this.dragObject()
      html.setAttribute('event', 'dragging')
    },
    getToolbar() {
      const toolbar = document.querySelector('.ld-toolbar')
      this.toolbarWidth = 380
      this.toolbarHeight = toolbar.clientHeight
      this.toolbar = toolbar
    },
    pxToInt(str = '0px') {
      return parseInt(str.substring(0, str.length - 2)) || 0
    },
    detectWindowResize() {
      window.addEventListener('resize', () => {
        const posLeftPX = this.pxToInt(this.toolbar.style.left)
        const posTopPX = this.pxToInt(this.toolbar.style.top)
        const overflowingRight = window.innerWidth < (posLeftPX + this.toolbarWidth)
        const overflowingBottom = window.toolbarHeight < (posTopPX + this.toolbarHeight)
        if (overflowingBottom) this.setToolbarPosition(-1, posTopPX - 50)
        if (overflowingRight) this.setToolbarPosition(posLeftPX - 50, -1)
      })
    },
    initWindowMouseUp() {
      document.onmouseup = () => {
        if (this.inDrag) this.endDrag()
      }
    }
  },
  mounted() {
    this.getToolbar()
    this.setToolbarPosition(window.innerWidth / 2 - 200, window.innerHeight / 2 - 200)
    this.detectWindowResize()
    this.initWindowMouseUp()
  }
}
