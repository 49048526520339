import LoadingSpinner from "../Utilities/LoadingSpinner";
import InputTextarea from "../Utilities/InputTextarea";
import InputFile from "../Utilities/InputFile";
import InputDropdown from "../Utilities/InputDropdown";
import ActionArea from "../Utilities/ActionArea";
import InputTextEditable from "../Utilities/InputTextEditable";
import ButtonDeleteFeedback from "./ButtonDeleteFeedback";

export default {
  components: {
    InputTextEditable,
    InputTextarea,
    InputFile,
    InputDropdown,
    LoadingSpinner,
    ActionArea,
    ButtonDeleteFeedback
  },
  template: `
  <form class="ld-feedback-card-form" :id="htmlID" :class="{'frozen': upload.loading }" ref="feedbackForm" action="javascript:void(0);">
    <p class="ld-draft-title">{{ formTitle }}</p>
    <fieldset ref="fieldset">
        <InputFile v-if="formComponents.contentReplacement && feedbackDomImageElement" label="Suggest new image" type="imageReplacement" @fileInput="setInput($event, 'imageReplacement')" @error="handleError"/>
        <InputTextEditable v-else-if="formComponents.contentReplacement" label="Content suggestion" :domElement="feedback.domElement" @setInput="setInput($event, 'textReplacement')"/>
        <InputTextarea :label="formComponents.comment" placeholder="type here" :initialTextInput="feedback.edits.comment.saved" :fileAttachment="!fileAttachmentBlocked" @setInput="setInput($event, 'comment')" @setFileInput="setInput($event, 'file')" @error="handleError"/>
        <InputDropdown v-if="!fileAttachmentBlocked && formComponents.category && !feedback.edits.category.saved" :label="formComponents.category" :options="categories" @setInput="setFeedbackCategory"/>
    </fieldset>
    <div v-if="errors" class="ld-feedback-form-error">
        <p v-if="errors.file">{{ errors.file }}</p>
        <p v-if="errors.upload">{{ errors.upload }}</p>
    </div>
   <LoadingSpinner v-if="upload.loading"/>
  </form>
  `,
  props: {
    feedback: Object,
  },
  data() {
    return {
      htmlID: `feedback-card-form-${this.feedback.clientID}`,
      categories: ['', 'UI', 'Frontend', 'Backend', 'Content', 'Bug', 'Feature Request', 'Suggestion', 'Integration', 'Testing', 'Hotfix', 'Improvement'],
      upload: {
        submitted: false,
        error: false,
        loading: false,
      },
      formTitle: this.feedback.getDraftTitle(),
      formComponents: this.feedback.getDraftComponents(),
      feedbackDomImageElement: this.feedback.domElement.tagName === 'img',
      errors: {
        file: "",
        upload: ""
      },
      fileAttachmentBlocked: false
      // fileAttachmentBlocked: this.$root.getProject().plan.commentAndFileBlocked
    }
  },
  methods: {
    closeFeedback() {
      this.$emit('close')
    },
    displayError(error) {
      this.errors[error.id] = error.value
    },
    setFeedbackCategory(value) {
      this.feedback.edits.category.draft = value
    },
    setInput(value, inputName) {
      if (typeof value === 'string') this.feedback.edits[inputName].draft = value
      else if (Array.isArray(value)) this.feedback.edits[inputName].draft = value.slice(0)
    },
    handleError(error) {
      this.errors.file = error
    },
    async updateFeedback() {
      this.errors.upload = false
      this.upload.submitted = false
      this.upload.loading = true

      await this.feedback.update()
        .then(() => {
          this.$emit('feedbackUpdated')
          this.upload.loading = false
          this.upload.submitted = true
        })
        .catch((error) => {
          console.log(error)
          this.upload.loading = false
          this.errors.upload = "Error updating Feedback. Please try later..."
        })
    },
    async uploadFeedback() {
      this.upload.submitted = false
      this.upload.loading = true
      this.errors.upload = false
      await this.feedback.upload()
        .then(({newFeedbackCount}) => {
          this.$emit('feedbackUploaded')
          this.$root.updateFeedbackCount(newFeedbackCount)
          this.upload.loading = false
          this.upload.submitted = true
        })
        .catch((error) => {
          this.upload.loading = false
          this.errors.upload = "Error uploading Feedback. Please try later..."
        })
    },
    focusOnInput() {
      const firstInput = document.querySelector(`#${this.htmlID} textarea`)
      if (firstInput) firstInput.focus()
    }
  },
  mounted() {
    window.EventBus.trigger('feedbackStateChange')
    this.focusOnInput()
  }
}

