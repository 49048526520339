import User from './User'
import planFeatures from "../Constants/planFeatures";

export default class Project {
  constructor(loop, plan, privateKey) {
    this.privateKey = privateKey
    this.loop = {
      name: loop.name || "",
      key: loop.key || "",
    }
    this.plan = {
      id: plan.id,
      feedbackCount: {
        max: plan.maximalCount,
        current: plan.uploadedCount
      }
    }
    this.user = null
  }

  getPrivateKey() {
    return this.privateKey
  }

  initUser() {
    this.user = new User()
  }

  updateUser(name = "", email = "", role = "") {
    this.user.update(name, email, role)
  }

  saveUser() {
    this.user.saveUser()
  }

  hasUser() {
    return this.user instanceof User && this.user.isValid()
  }

  getUserName() {
    return this.user ? this.user.name : "anonym"
  }

  getLoopName() {
    return this.loop.name
  }

  setFeedbackCount(count) {
    this.plan.feedbackCount.current = Number(count)
  }


  setPlanFeatures() {
    const plan = planFeatures[this.plan.id]

    if (plan) {
      this.plan.valid = true
      this.plan.name = plan.name
      this.plan.selectorsBlocked = plan.selectorsBlocked
      this.plan.commentAndFileBlocked = plan.commentAndFileBlocked
      this.plan.feedbacksLimited = plan.feedbacksLimited
    } else {
      this.plan.valid = false
    }
  }


  init() {
    this.setPlanFeatures()
    return new Promise((resolve, reject) => {
      if (this.plan.valid) {
        this.initUser()
        resolve()
      } else {
        reject("plan is not valid")
      }
    })

  }

  remove() {
    this.user.remove()
  }
}