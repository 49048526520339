import Icon from "./Icon";
import firebase from "../../globals/services/firebase";

export default {
  components: {Icon},
  template: `
   <div class="ld-file-input-wrapper" :class="{'ld-file-upload-success': fileUpload.uploaded, 'ld-file-upload-loading': fileUpload.loading}">
      <label :for="fileInputID"  class="ld-file-input-label">
        <span class="ld-label-title" v-if="label">{{ label }}</span>
        <div class="ld-file-input-overlay">
          <Icon icon="paperclip"/>
          <span ref="spanFileInput">{{ fileInput || 'add file' }}</span>
        </div>
      </label>
      <input @change="uploadFile" type="file" :id="fileInputID" name="fileInputID" accept="image/png, image/jpeg, .pdf" hidden>
   </div>
`,
  props: {
    label: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'text'
    },
  },
  data() {
    return {
      fileInputID: `ld-input-${this.type}-id-${Date.now()}`,
      fileInput: "",
      fileUpload: {
        loading: false,
        error: false,
        uploaded: false
      }
    }
  },
  watch: {
    'fileUpload.error'(value) {
      this.$emit('error', value)
    },
  },
  methods: {
    async uploadFile(event) {
      this.fileUpload.error = ""
      const fileInput = event.target
      const hasFiles = fileInput.files.length > 0
      const file = hasFiles ? fileInput.files[0] : null
      const filesize = file ? ((file.size / 1024) / 1024).toFixed(2) : 0; // MB

      if (!file) {
        this.fileUpload.error = "File upload: Please provide a file if you want to upload a file."
      } else if (filesize <= 1) {
        this.fileUpload.loading = true
        this.fileUpload.error = ""
        this.fileUpload.uploaded = false
        this.fileInput = file.name
        await this.uploadToFirebase(file)
      } else if (filesize > 1) {
        this.fileUpload.error = `The file size of your file is ${filesize}MB and exceeds the 1MB limit.`
      } else {
        this.fileUpload.error = "File upload: An error has occurred. Your file was not uploaded."
      }
    },
    async uploadToFirebase(file) {
      const formData = new FormData();
      formData.append('assets', file, file.name);
      this.fileUpload.loading = false
      await firebase.uploadFile(formData)
        .then((res) => {
          this.fileUpload.error = ""
          this.fileUpload.uploaded = true
          this.$emit('fileInput', res)
        })
        .catch((error) => {
          this.fileUpload.error = "File upload: An error has occurred. Your file was not uploaded."
        })
    },
  },
  mounted() {
  }
}