import ImageElement from "../../Utilities/Image";

export default {
  components: {ImageElement},
  template: `
<div class="ld-instruction-block">   
   <h4><span class="ld-info-title">Launch Loopdesk</span> </h4> 
   <div class="ld-grid">
      <div>
         <p>There is an orange + Button you can click to launch Loopdesk. The toolbar will open itself, as soon as you click the button. </p>
         <p>Under the buttons is smaller one you can click to leave Loopdesk. Your credentials will be removed after you leave loopdesk. The next time you want to start Loopdesk you will have to provide the loopdesk-key. </p>
      </div>
      <ImageElement path="tutorial/instruction-launch" filetype="jpg"/>
</div>


</div>
  `,
  data() {
    return {}
  },
  computed: {},
  methods: {},
  mounted() {

  }
}
