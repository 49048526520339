import './style/main.scss'
import keys from './globals/services/keys'
import scripts from "./globals/resources/scripts";
import vueInstance from "./globals/resources/vueInstance";
import RouteWatcher from "./globals/constructors/Utils/RouteWatcher";
import Billboard from "./globals/constructors/Utils/Billboard";
import EventBus from "./globals/constructors/Utils/EventBus";

const getQuery = (name) => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const queries = Object.fromEntries(urlSearchParams.entries());
  return queries ? queries[name] : null
}


(async function () {
  const hasScrollParams = !!getQuery('loopdesk-scroll-y') || !!getQuery('loopdesk-scroll-x')
  const scrollParamY = getQuery('loopdesk-scroll-y') || 0
  const scrollParamX = getQuery('loopdesk-scroll-x') || 0

  if (hasScrollParams) { // scroll params are only used in iFrame in app.loopdesk -> no need for the client to check keys
    console.log("has scroll params")
    document.documentElement.style.marginBottom = '54vh'
    window.scrollTo({
      top: Number(scrollParamY) || 0,
      left: Number(scrollParamX) || 0,
      behavior: "smooth"
    })
  } else { // if no scroll params defined -> check keys
    await keys
      .check()
      .then(() => {
        Billboard.write('Login successful')
        window.EventBus = new EventBus;
        scripts.init()
          .then(() => {
            vueInstance.init()
            RouteWatcher.initObserver()
          })
      })
      .catch((error) => {
        if (error !== 'key-missing') Billboard.write(error)
      })
  }
}())


