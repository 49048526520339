export default {
  getDomElementByIndexAndTag: {
    encode: (element) => {
      if (!(element instanceof HTMLElement)) {
        return false
        // throw new Error('element must be of type `HTMLElement`.');
      }

      const parentElements = (domElement) => {
        const parents = [];
        while (domElement) {
          const tagName = domElement.nodeName.toLowerCase();
          const children = domElement.parentNode ? Array.from(domElement.parentNode.children).filter(n => n.tagName === domElement.tagName) : []
          const index = children && children.length > 0 ? children.indexOf(domElement) + 1 : null

          parents.unshift({
            tag: tagName,
            i: index,
          });

          domElement = domElement.parentNode !== document ? domElement.parentNode : false;
        }

        return parents;
      };


      const buildPathString = (parents) => {
        return JSON.stringify(parents)
      };


      return buildPathString(parentElements(element));
    },

    decode: (jsonObj) => {
      if (!jsonObj) return null

      const parents = JSON.parse(jsonObj)
      if (!parents && parents.length <= 0) return null

      const selector = parents
        .map(it => {
          const index = typeof it.i === 'number' && it.i > 0 ? `:nth-of-type(${it.i})` : null
          return `${it.tag}${index || ''}`
        })
        .join(' ')

      return document.querySelector(selector) || null
    }
  },

  thisCouldBeAnotherMethod: {
    encode: (element) => {

    },

    decode: (jsonObj) => {

    },
  },

  andThisCouldBeAnotherMethod: {
    encode: (element) => {

    },

    decode: (jsonObj) => {

    },
  },

}