import feedbackData from "../Constants/feedbackData";


export default function FeedbackType(name) {
  this.id = name;
  this.cursor = null;
  this.name = `${name} selector`;
  this.marker = feedbackData[name].marker;
  this.icon = `selector-${name}-icon`;
  this.title = feedbackData[name].title
  this.description = feedbackData[name].description
  this.instruction = feedbackData[name].instruction
  this.infoComponents = feedbackData[name].infoComponents
  this.draftFormComponents = feedbackData[name].draftFormComponents
  this.draftTitle = feedbackData[name].draftTitle
  this.position = feedbackData[name].position
  this.tutorial = {
    title: feedbackData[name].tutorialTitle,
    instructions: feedbackData[name].tutorialDescription,
  }
  this.mobileSupport = feedbackData[name].mobileSupport
}

